import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@firebase/firestore';

import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReceiptUpload } from '../models/receipt-upload.model';
import { Receipt } from '../models/receipt';
import { ReceiptItem } from '../models/receipt-item';
// import { combineLatestWith } from 'rxjs/operators';
import { map, pipe } from 'rxjs';

import { combineLatest } from 'rxjs';
// import { Firestore, collectionData, collection } from '@angular/fire/firestore';
// import { addDoc, collection, collectionData, doc, Firestore, increment, orderBy, query, serverTimestamp, updateDoc } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { deleteField } from '@angular/fire/firestore';

import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/compat/firestore';
import {
  serverTimestamp,
  arrayUnion,
  arrayRemove
} from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  private dbPath = 'receipts';
  receiptsRef: AngularFirestoreCollection<Receipt>;

  private basePath = '/cde30fef-ff9e-44e0-aad3-cd2092d6e7c9';
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService
  ) {
    this.receiptsRef = afs.collection(
      this.dbPath,
      // (ref) => ref.where('status', '!=', 'public')
      // (ref) => ref.where('owner', '==', 'DxNT16E982YVPhJi1gHBMYQBp0o1')

      (ref) => ref.orderBy('createdAt', 'desc').where('status', '==', 'public')
    );
    // .where('status', '==', 'public')
  }

  getNewId() {
    return this.afs.createId();
  }

  pushDataURLToStorage(
    receiptUpload: ReceiptUpload
  ): Observable<number | undefined> {
    // const id = this.afs.createId();
    const fileName = `${receiptUpload.id}.jpg`;
    const filePath = `${this.basePath}/${fileName}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = storageRef.putString(receiptUpload.data, 'data_url');
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            receiptUpload.url = downloadURL;
            this.saveFileData(receiptUpload);
          });
        })
      )
      .subscribe();
    return uploadTask.percentageChanges();
  }

  private saveFileData(receiptUpload: ReceiptUpload): void {
    // status = 'private';
    // owner = this.auth.userInfo?.uid;

    const data: Receipt = {
      id: receiptUpload.id,
      subtotal: 0,
      createdAt: serverTimestamp(),
      people: [],
      merchant_name: '',
      url: receiptUpload.url,
      tax: 7,
      tip: 15,
      status: 'public',
      // owner: this.auth.userInfo?.uid || deleteField(),
      originalSize: receiptUpload.originalSize,
      newSize: receiptUpload.newSize,
      total: 0,
      confirmed_subtotal: false
    };
    if (this.auth.userInfo?.uid) {
      data['status'] = 'private';
      data['owner'] = this.auth.userInfo?.uid;
    }

    const item = this.afs.doc<Receipt>(`receipts/${receiptUpload.id}`);
    item.set(data);
  }

  getCombineReceipts() {
    console.log('getCombineReceipts');
    const fooPosts = this.afs
      .collection(
        this.dbPath,
        // (ref) => ref.where('status', '!=', 'public')
        // (ref) => ref.where('owner', '==', 'DxNT16E982YVPhJi1gHBMYQBp0o1')

        (ref) =>
          ref.orderBy('createdAt', 'desc').where('status', '==', 'public')
      )
      .snapshotChanges();

    const barPosts = this.auth.user.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .collection(this.dbPath, (ref) =>
              ref.orderBy('createdAt', 'desc').where('owner', '==', user.uid)
            )
            .snapshotChanges();
        } else {
          return of([]);
        }
      })
    );

    let trigger = '';

    const combinedList = combineLatest<any[]>([
      fooPosts.pipe(tap(() => (trigger = 'foo'))),
      barPosts.pipe(tap(() => (trigger = 'bar')))
    ]).pipe(
      map((arr) => {
        console.log(`triggered by ${trigger}`);

        console.log(arr[0].length, arr[1].length);
        // console.table(arr[0]);
        // console.table(arr[1]);
        return arr.reduce((acc, cur) => acc.concat(cur));
      })
    );

    return combinedList;
  }

  getAll(): AngularFirestoreCollection<Receipt> {
    return this.receiptsRef;
  }

  deleteReceipt(id: string): void {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.update({ status: 'deleted', owner: 'DxNT16E982YVPhJi1gHBMYQBp0o1' });
  }

  restoreReceipt(id: string, status: string, owner: string) {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.update({ status: status, owner: this.auth.userInfo?.uid });
  }

  realDeleteReceipt(id: string): void {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.delete();
  }

  lock(id: string) {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.update({ status: 'private', owner: this.auth.userInfo?.uid });
  }

  unlock(id: string) {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.update({ status: 'public', owner: deleteField() });
  }

  // this.uploadService.updateReceiptItem(this.currentReceipt.id, item.id, {
  //   like: item.like
  // })

  updateReceipt(id: string, data: Partial<Receipt>): void {
    const item = this.afs.doc<Receipt>(`receipts/${id}`);
    item.update(data);
  }

  getReceiptItems(receiptId: string): AngularFirestoreCollection<ReceiptItem> {
    return this.receiptsRef
      .doc(receiptId)
      .collection('items', (ref) => ref.orderBy('createdAt', 'asc'));
  }

  updateReceiptItem(
    id: string,
    itemId: string,
    data: Partial<ReceiptItem>
  ): void {
    // console.log(id, itemId, data)
    this.afs
      .collection('receipts')
      .doc(id)
      .collection('items')
      .doc(itemId)
      .update(data);
  }
  addReceiptItem(id: string, createdAt: Date | null): string {
    // let createdAt = serverTimestamp();
    let created = serverTimestamp();

    if (createdAt) {
      created = Timestamp.fromDate(createdAt);
    }
    const receiptId = this.afs.createId();
    this.afs
      .collection('receipts')
      .doc(id)
      .collection('items')
      .doc(receiptId)
      .set({
        name: 'new item',
        quantity: 1,
        total_price: 0,
        createdAt: created
      });
    return receiptId;
  }

  splitReceiptItem(receiptId: string, item: ReceiptItem) {
    const batch = this.afs.firestore.batch();
    const old = this.afs
      .collection('receipts')
      .doc(receiptId)
      .collection('items')
      .doc(item.id).ref;
    const quantity = item.quantity;
    const total_price = item.total_price / quantity;
    item.quantity = 1;
    item.total_price = total_price;
    batch.set(old, { ...item });
    for (let i = 1; i < quantity; i++) {
      const newId = this.afs.createId();
      const newItem = this.afs
        .collection('receipts')
        .doc(receiptId)
        .collection('items')
        .doc(newId).ref;
      batch.set(newItem, { ...item, id: newId });
    }

    batch.commit().then((r) => {
      console.log(r);
    });
  }

  deleteReceiptItem(receiptId: string, itemId: string) {
    console.log(receiptId, itemId);

    this.afs
      .collection('receipts')
      .doc(receiptId)
      .collection('items')
      .doc(itemId)
      .delete();
  }

  addEveryoneToReceiptItem(id: string, itemId: string, people: string[]) {
    this.afs
      .collection('receipts')
      .doc(id)
      .collection('items')
      .doc(itemId)
      .update({ people: arrayUnion(...people) });
  }

  addPeopleToReceiptItem(id: string, itemId: string, people: string): void {
    // console.log(id, itemId, people)
    this.afs
      .collection('receipts')
      .doc(id)
      .collection('items')
      .doc(itemId)
      .update({ people: arrayUnion(people) });
  }
  removePeopleFromReceiptItem(
    id: string,
    itemId: string,
    people: string
  ): void {
    // console.log(id, itemId, people)
    console.log('removePeopleFromReceiptItem');
    this.afs
      .collection('receipts')
      .doc(id)
      .collection('items')
      .doc(itemId)
      .update({ people: arrayRemove(people) });
  }
}
