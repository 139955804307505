import { Component, OnInit, ViewChild } from '@angular/core';
import { ReceiptListComponent } from '../components/receipt-list/receipt-list.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  scrollToReceipt = false;
  constructor() {}

  receiptList: ReceiptListComponent;
  @ViewChild(ReceiptListComponent, { static: false })
  set childComponent(child: ReceiptListComponent) {
    if (child) {
      this.receiptList = child;
    }
    if (this.scrollToReceipt) {
      this.scrollToReceiptNow();
    }
  }

  currentReceiptId: any;

  updateCurrentReceiptId(id: string) {
    console.log('updateCurrentReceiptId');
    this.currentReceiptId = id;
    this.scrollToReceipt = true;
    this.receiptList.receiptDetail.scrollComplete = false;
    this.scrollToReceiptNow();
  }

  scrollToReceiptNow() {
    if (this.receiptList) {
      this.receiptList.scrollToReceipt = true;
      this.receiptList.scrollToReceiptNow();
    }
  }
  ngOnInit(): void {}
}
