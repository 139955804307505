import { Component, Version } from '@angular/core';
import { VERSION } from '../environments/version';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { SwPush, SwUpdate } from '@angular/service-worker';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { AuthService } from './services/auth.service';
import { map } from 'rxjs';
import { arrayRemove, arrayUnion, deleteField } from '@angular/fire/firestore';
import { mergeMap, mergeMapTo } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showLoginButton = false;
  showLogoutButton = false;

  title = 'Munchies Calculator';
  notification: string;

  version: any;
  constructor(
    private swUpdate: SwUpdate,
    private afMessaging: AngularFireMessaging,
    public auth: AuthService,
    private afs: AngularFirestore,
    public swPush: SwPush
  ) {
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      console.log(action, notification);
    });
    this.notification = this.get_notification_permission();

    this.auth.user.pipe(map((u) => !!u)).subscribe((isLoggedIn) => {
      this.showLoginButton = !isLoggedIn;
      this.showLogoutButton = isLoggedIn;
    });

    this.afMessaging.messages.subscribe((payload) => {

      console.log(payload);
      // alert((payload.data as any).body);
    });
    this.version = VERSION.buildTime + ' ' + VERSION.raw;
    console.log(
      `Application version is: version (from package.json)=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}, buildTime=${VERSION.buildTime}`
    );

    // updates.versionUpdates.subscribe((evt) => {
    //   console.log(evt);

    //   switch (evt.type) {
    //     case 'VERSION_DETECTED':
    //       console.log(`Downloading new app version: ${evt.version.hash}`);
    //       break;
    //     case 'VERSION_READY':
    //       console.log(`Current app version: ${evt.currentVersion.hash}`);
    //       console.log(
    //         `New app version ready for use: ${evt.latestVersion.hash}`
    //       );
    //       // alert(`New app version ready for use: ${evt.latestVersion.hash}`)
    //       break;
    //     case 'VERSION_INSTALLATION_FAILED':
    //       console.log(
    //         `Failed to install app version '${evt.version.hash}': ${evt.error}`
    //       );
    //       break;
    //   }
    // });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (
          confirm(
            "You're using an old version of the calculator. Want to update?"
          )
        ) {
          window.location.reload();
        }
      });
    }
  }

  reload() {
    window.location.reload();
  }
  deleteToken() {
    this.afMessaging.getToken
      // @ts-expect-error promptUser can not be found even though it exists
      .pipe(mergeMap((token) => this.afMessaging.deleteToken(token)))
      .subscribe((token) => {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
          `users/${this.auth.userInfo?.uid}`
        );
        const data = {
          // notifications: arrayRemove(token)
          notification: deleteField()
        };
        this.notification = 'default';
        return userRef.update(data);

        console.log('Token deleted!');
      });
  }

  get_notification_permission() {
    if (
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    ) {
      return Notification.permission;
    } else {
      return 'denied';
    }
  }

  requestTokenOneTime() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.getToken))
      .subscribe(
        (token) => {
          console.log('Permission granted! Save to the server!', token);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getToken() {
    this.afMessaging.getToken.subscribe((token) => {
      console.log('Token ', token);
    });
  }

  requestPermission() {
    this.afMessaging.requestToken.subscribe({
      next: (token) => {
        console.log(token);

        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
          `users/${this.auth.userInfo?.uid}`
        );
        const data = {
          // notifications: arrayUnion(token)
          notification: token
        };
        this.notification = 'granted';
        return userRef.update(data);
      },
      error: (error) => console.error(error)
    });
  }
}
