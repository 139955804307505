import { Component, OnInit, ViewChild } from '@angular/core';
import { Receipt } from 'src/app/models/receipt';
import { ActivatedRoute } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/compat/firestore';
import { ReceiptDetailsComponent } from '../components/receipt-details/receipt-details.component';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  currentReceipt?: Receipt;

  @ViewChild(ReceiptDetailsComponent, { static: false })
  set childComponent(child: ReceiptDetailsComponent) {
    if (child) {
      child.scrollToPeople = true;
    }
  }
  constructor(private route: ActivatedRoute, private afs: AngularFirestore) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.fetchDocument(id);
    });
  }

  fetchDocument(id: string): void {
    this.afs
      .doc<Receipt>(`receipts/${id}`)
      .valueChanges()
      .subscribe((receipt) => {
        this.currentReceipt = receipt;
      });
  }
}
