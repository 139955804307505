<div #topReceipt class="topReceipt"></div>
<div *ngIf="currentReceipt">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <!-- [cdkCopyToClipboard]=" window.location.origin + '/receipts/' + currentReceipt.id" -->
    <mat-card style="width: 90%; margin-bottom: 20px;">
      <!-- class="mat-button mat-button-base mat-primary" -->
      <mat-card-title>{{currentReceipt.merchant_name}} <span class="mat-button mat-primary"
          [cdkCopyToClipboard]="getLink()" mat-raised-button color="accent">Share</span>
        <span class="mat-button mat-primary" mat-raised-button color="accent" (click)="openSharingDialog()">QR
          Code</span>
      </mat-card-title>

      <mat-card-content>
        <div fxLayout="column" fxFlexFill fxLayoutAlign="space-evenly stretch">
          <div>
            ID: {{currentReceipt.id}} ({{currentReceipt.status}})
          </div>
          <!-- <ng-template #processing>
            <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
            Analyzing receipt, should take less than 10 seconds
          </ng-template> -->
          <div *ngIf="currentReceipt.merchant_name.length==0">
            <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
            <mat-label>Analyzing receipt, should take less than 10 seconds</mat-label>
          </div>

          <mat-form-field>
            <mat-label>Restaurant Name</mat-label>
            <input matInput [(ngModel)]="currentReceipt.merchant_name" (change)="update()">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Enter subtotal (Food + Liquor)</mat-label>
            <input matInput type="number" [(ngModel)]="currentReceipt.subtotal" (change)="update()">
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          <div *ngIf="items && items.length!=0 && !subtotal_match">
            <span class="mat-error">Subtotal from receipt ${{this.currentReceipt.subtotal | number: "1.2-2"}} does not
              match calculated subtotal ${{this.calculated_subtotal | number: "1.2-2"}} (off by ${{this.subtotal_diff |
              number: "1.2-2"}}) from all items.</span>
            <span>Please review and update the price for each item.</span>
          </div>
          <mat-form-field>
            <mat-label>Tax Percentage</mat-label>
            <input matInput style="max-width: 2.5em" type="number" [(ngModel)]="currentReceipt.tax" (change)="update()">
            <span matTextSuffix>% * ${{currentReceipt.subtotal}} = ${{currentReceipt.subtotal *
              (currentReceipt.tax/100.0)| number: "1.2-2" }}</span>
          </mat-form-field>
          <div class="button-container" fxFlexOffset="-20px">
            <button mat-raised-button color="accent" (click)="currentReceipt.tax=7 ; update()">7%</button>
            <button mat-raised-button color="accent" (click)="currentReceipt.tax=6.25 ; update()">6.25%</button>
            <button mat-raised-button color="accent" (click)="currentReceipt.tax=8.5 ; update()">8.5%</button>
            <button mat-raised-button color="accent" (click)="openTaxDialog()">Set $ amount</button>
          </div>
          <mat-divider></mat-divider>
          <mat-form-field>
            <mat-label>Tip Percentage</mat-label>
            <input matInput style="max-width: 2.5em;" type="number" [(ngModel)]="currentReceipt.tip"
              (change)="update()">
            <span matTextSuffix>% * ${{currentReceipt.subtotal}} = ${{currentReceipt.subtotal * (currentReceipt.tip
              /100.0)| number: "1.2-2" }}</span>
          </mat-form-field>


          <div class="button-container" fxFlexOffset="-20px">
            <button mat-raised-button color="accent" (click)="currentReceipt.tip=15 ; update()">15%</button>
            <button mat-raised-button color="accent" (click)="currentReceipt.tip=18 ; update()">18%</button>
            <button mat-raised-button color="accent" (click)="currentReceipt.tip=20 ; update()">20%</button>
            <button mat-raised-button color="accent" (click)="openDialog()">Set $ amount</button>
          </div>

          <mat-divider></mat-divider>

          <mat-form-field class="bold">
            <mat-label>Total (Subtotal + Tax + Tip)</mat-label>
            <input matInput type="number" value="{{ currentReceipt.total | number: '1.2-2' }}" readonly disabled>
            <span matPrefix>$&nbsp;</span>

          </mat-form-field>
          <div class="bold">${{ currentReceipt.total | number: '1.2-2' }} = ${{currentReceipt.subtotal}}(subtotal) +
            ${{currentReceipt.subtotal * (currentReceipt.tax /100.0)| number: "1.2-2" }}({{currentReceipt.tax|
            number:
            "1.2-2"}}% tax) +
            ${{currentReceipt.subtotal * (currentReceipt.tip /100.0)| number: "1.2-2" }}({{currentReceipt.tip|
            number:
            "1.2-2"}}% tip)
          </div>
        </div>

      </mat-card-content>
    </mat-card>



    <mat-card style="width: 90%; margin-bottom: 20px;">
      <mat-card-title>Breakdown<span class="mat-button mat-primary" [cdkCopyToClipboard]="getLink()" mat-raised-button
          color="accent">Share</span>
        <span class="mat-button mat-primary" mat-raised-button color="accent" (click)="openSharingDialog()">QR
          Code</span></mat-card-title>
      <mat-card-content>
        <div fxLayout="column" fxFlexFill fxLayoutAlign="space-evenly stretch">


          <ng-template #notMatch>
            <div *ngIf="items && items.length!=0" class="error">
              Subtotal from receipt ${{this.currentReceipt.subtotal}} does not match calculated subtotal
              ${{this.calculated_subtotal | number: "1.2-2"}} (off by ${{this.subtotal_diff | number: "1.2-2"}}) from
              all items.
            </div>
            <div>
              Please review and update the price for each item.
            </div>
          </ng-template>

          <ng-container *ngIf="subtotal_match; else notMatch">
            <mat-form-field>
              <mat-label>Who was there?(Select from list or enter new names)</mat-label>
              <mat-chip-list #chipList aria-label="People selection">

                <mat-chip *ngFor="let person of this.currentReceipt.people" (removed)="remove2(person)">
                  {{person}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>

                <div>
                  <input matInput placeholder="Enter new name or names separate by comma" #fruitInput
                    [formControl]="fruitCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    matChipInputAddOnBlur="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)" matAutocompletePosition="below"
                    (focus)="searchText.scrollIntoView()" #searchText>
                </div>
              </mat-chip-list>
            </mat-form-field>
            <!-- FIXME you could move mat-autocomplete to inside mat-form-fiel and see if you like that better  -->

            <!-- optionActivated
              optionSelected -->
            <mat-autocomplete #auto="matAutocomplete">
              <mat-selection-list #fruitSelectionList (selectionChange)="onFruitSelectionChange($event)">
                <mat-list-option *ngFor="let fruit of filteredFruits | async" [value]="fruit"
                  [selected]="isFruitSelected(fruit)">
                  {{fruit}}
                </mat-list-option>
                <mat-option [style.display]="'none'"></mat-option>

              </mat-selection-list>

            </mat-autocomplete>

          </ng-container>
          <ng-container *ngIf="subtotal_match && this.currentReceipt.people.length!=0">
            <div *ngIf="venmoProfile">
              Venmo Display Name: {{venmoProfile.display_name}} <img
                alt="profile image for {{venmoProfile.display_name}}"
                *ngIf="venmoProfile.profile_picture_url?.indexOf('no-image') === -1" class="left"
                src="{{venmoProfile.profile_picture_url}}" style="float:left;width:100px;height:100px;">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="border22222">
              <mat-form-field fxFlex="grow">
                <mat-label>Who to Venmo (Venmo username)</mat-label>
                <input matInput value="{{currentReceipt.venmo}}" (change)="updateVenmo(getValue($event))" />

              </mat-form-field>
              <button mat-icon-button [color]="scannerEnabled ? 'primary' : '' " class="border222"
                (click)="scannerEnabled = !scannerEnabled">
                <mat-icon>qr_code_scanner</mat-icon>
              </button>
              <!-- matIconSuffix -->
              <span>
                <button *ngIf="this.auth.userInfo?.venmo" mat-raised-button color="accent"
                  (click)="currentReceipt.venmo = auth.userInfo!.venmo ; update()">Use mine</button>
                <button
                  *ngIf=" this.auth.userInfo && this.currentReceipt.venmo && (!this.auth.userInfo?.venmo || this.auth.userInfo?.venmo!=this.currentReceipt.venmo)"
                  mat-raised-button color="accent" (click)="updateMyVenmo()">Update mine</button>
              </span>

              <div>

              </div>
            </div>
            <div *ngIf="scannerEnabled">
              <zxing-scanner (scanSuccess)="scanSuccessHandler($event)" [enable]="scannerEnabled"></zxing-scanner>
            </div>
            <div #topPeople class="topPeople"></div>
            <table mat-table [dataSource]="breakdown" style=" margin-bottom: 20px;" multiTemplateDataRows
              [trackBy]="trackByName" recycleRows>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name (Expand for detail) </th>
                <td mat-cell *matCellDef="let element">
                  <span matBadge="{{element.items.length}}" matBadgeOverlap="false">{{element.name}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef>Combined</td>
              </ng-container>
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef> Total </th>
                <td mat-cell *matCellDef="let element"> ${{ (element.subtotal + element.tip + element.tax) | number:
                  "1.2-2" }}</td>
                <td mat-footer-cell *matFooterCellDef>
                  <div *ngIf="total_match; else notMatchTotal" class="success">
                    ${{ calculated_total| number: "1.2-2" }}
                  </div>
                  <ng-template #notMatchTotal>
                    <div>
                      ${{this.calculated_total | number: "1.2-2"}}
                    </div>
                    <div class="error">
                      $({{ this.currentReceipt.total - this.calculated_total | number: "1.2-2"}})
                    </div>
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="subtotal">
                <th mat-header-cell *matHeaderCellDef> Subtotal </th>
                <td mat-cell *matCellDef="let element">${{element.subtotal | number: "1.2-2" }} </td>
                <td mat-footer-cell *matFooterCellDef> ${{ itemized_subtotal| number: "1.2-2" }} </td>
              </ng-container>
              <ng-container matColumnDef="tip">
                <th mat-header-cell *matHeaderCellDef> Tip </th>
                <td mat-cell *matCellDef="let element">${{element.tip | number: "1.2-2" }} </td>
                <td mat-footer-cell *matFooterCellDef> ${{ total_tip| number: "1.2-2" }} </td>
              </ng-container>
              <ng-container matColumnDef="tax">
                <th mat-header-cell *matHeaderCellDef> Tax </th>
                <td mat-cell *matCellDef="let element">${{element.tax | number: "1.2-2" }} </td>
                <td mat-footer-cell *matFooterCellDef> ${{ total_tax| number: "1.2-2" }} </td>
              </ng-container>
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <ng-container *ngIf="element == expandedElement">
                    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" class="example-element-detail"
                      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div *ngFor="let item of element.items; let i = index">
                        <div>{{item.name}} </div>
                      </div>
                      <div>
                        <div *ngIf="currentReceipt.venmo">
                          <div *ngIf="!paidForMeal(currentReceipt.venmo,element.name)">
                            <a mat-raised-button color="accent" target="_blank"
                              [href]="sanitizeUrl(currentReceipt.venmo, 'pay', element.total,currentReceipt.merchant_name+' (' + element.name + ')', element.note, 'private')">Pay
                              {{currentReceipt.venmo}}</a>

                            <a mat-raised-button color="accent" (click)="openVenmoPaymentDialog(element.name, currentReceipt.venmo, element.total,
                          currentReceipt.merchant_name +' (' + element.name + ')',element.note, 'private' )">Pay
                              via <mat-icon>qr_code_2</mat-icon></a>
                          </div>

                        </div>
                        <div *ngIf="shouldShowCharge(currentReceipt.venmo,element.name); else checkForChao">
                          <a mat-raised-button color="accent" target="_blank"
                            [href]="sanitizeUrl(auth.friends[element.name.toLowerCase()]['venmo'], 'charge', element.total,currentReceipt.merchant_name+' (' + element.name + ')', element.note, 'private')">Charge
                            {{element.name}}</a>
                          <button mat-raised-button color="accent"
                            (click)="currentReceipt.venmo = auth.friends[element.name.toLowerCase()]['venmo'] ; update()">
                            {{element.name}} paid the bill</button>
                        </div>
                        <ng-template #checkForChao>
                          <div *ngIf="element.name.toLowerCase()=='chao' && currentReceipt.venmo!='ChaoXie'">
                            <a mat-raised-button color="accent" target="_blank"
                              [href]="sanitizeUrl('ChaoXie', 'charge', element.total,currentReceipt.merchant_name+' (' + element.name + ')', element.note, 'private')">Charge
                              Chao</a>
                          </div>
                        </ng-template>
                        <div *ngIf="this.auth.userInfo">
                          <button mat-raised-button color="accent"
                            (click)="openFriendVenmoDialog(element.name, auth.friends[element.name.toLowerCase()])">
                            Manage {{element.name}}'s venmo</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                [class.example-expanded-row]="expandedElement === row"
                (click)="expandedElement = expandedElement === row ? null : row"></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

            </table>

          </ng-container>




          <section>
            <div class="border23333">
              <div>
                <mat-slide-toggle [(ngModel)]="quickEdit" color="primary">QuickEdit</mat-slide-toggle>

                <button mat-stroked-button (click)="addNewItem(currentReceipt.id)">
                  <mat-icon>note_add</mat-icon>
                </button>
                <button mat-stroked-button (click)="accordion.closeAll()"> <mat-icon>unfold_less</mat-icon></button>
                <button mat-stroked-button (click)="accordion.openAll()"><mat-icon>unfold_more</mat-icon></button>
                <button mat-stroked-button (click)="showTranslation = !showTranslation">
                  <mat-icon>g_translate</mat-icon>
                </button>
              </div>
              <mat-accordion class="border22222" multi>
                <div id="{{item.id}}" *ngFor="let item of items; let i = index">
                  <mat-expansion-panel hideToggle [expanded]="openItems.includes(item.id)"
                    (opened)="openItems.push(item.id)" (closed)="removeItemFromOpenPanel(item.id)">
                    <mat-expansion-panel-header class="border222"
                      [ngClass]=" (this.highLightMissingItems && (item.people == null || item.people.length==0)) ? 'itemsWarning' : 'not-selected' ">
                      <mat-panel-title class="border222">
                        {{ item.quantity }} {{ item.name }}:
                        <button *ngIf=" item.like" mat-icon-button color="warn">
                          <mat-icon>favorite</mat-icon>
                        </button>
                      </mat-panel-title>
                      <mat-panel-description class="border2222">
                        <div *ngIf="item.people && item.people.length > 0">
                          ({{item.people.length}})

                        </div>

                        ${{ item.total_price.toFixed(
                        2
                        ) }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>


                      <div *ngIf="!quickEdit" fxLayout="row wrap">
                        <mat-form-field>
                          <mat-label>Name</mat-label>
                          <input matInput ty id="quantity" id="name" [(ngModel)]="item.name"
                            (change)="updateReceiptItem(this.currentReceipt.id, item.id, {'name': item.name})">
                        </mat-form-field>


                        <mat-form-field>
                          <mat-label>Quantity</mat-label>
                          <input matInput type="number" id="quantity" [(ngModel)]="item.quantity"
                            (change)="updateReceiptItem(this.currentReceipt.id, item.id, {'quantity': item.quantity})">
                        </mat-form-field>
                        <mat-form-field floatLabel="always">
                          <mat-label>Total Price</mat-label>
                          <input matInput type="number" placeholder="0" [(ngModel)]="item.total_price"
                            (change)="updateReceiptItem(this.currentReceipt.id, item.id, {'total_price': item.total_price})">
                          <span matPrefix>$&nbsp;</span>
                        </mat-form-field>

                        <mat-divider></mat-divider>

                      </div>
                      <div *ngIf="currentReceipt.people?.length!=0">

                        <mat-chip-list>
                          <ng-container *ngFor="let p of currentReceipt.people">

                            <ng-container *ngIf="item.people && item.people.includes(p); else notIncluded ">
                              <mat-chip selected color="primary"
                                (click)="togglePeople(this.currentReceipt.id, item, p)">
                                {{p}}</mat-chip>
                            </ng-container>

                            <ng-template #notIncluded>
                              <mat-chip (click)="togglePeople(this.currentReceipt.id, item, p)">{{p}}</mat-chip>
                            </ng-template>
                          </ng-container>
                          <mat-chip (click)="addEveryoneToReceiptItem(this.currentReceipt, item)">Shared by all
                          </mat-chip>
                        </mat-chip-list>
                        <mat-divider></mat-divider>
                      </div>


                      <div>


                        <mat-action-list>
                          <!-- fxFlex="grow" -->
                          <div>
                            <button matTooltip="Split this item into multiple items" *ngIf="item.quantity !=1"
                              mat-flat-button color="primary" (click)="splitReceiptItem(currentReceipt.id, item)">Split
                              into
                              {{item.quantity}} items</button>


                            <button *ngIf="item.like" mat-icon-button color="warn"
                              (click)="toggleLike(this.currentReceipt.id, item)">
                              <mat-icon>favorite</mat-icon>
                            </button>

                            <button *ngIf="!item.like" mat-icon-button matTooltip="Like this item"
                              (click)="toggleLike(this.currentReceipt.id, item)">
                              <mat-icon>favorite</mat-icon>
                            </button>

                          </div>
                          <div *ngIf="showTranslation && item.simple_description">{{item.simple_description}}</div>
                          <div *ngIf="showTranslation && item.description">{{item.description}}</div>
                          <div style="display: flex; justify-content:flex-end">
                            <button mat-mini-fab color="warn" (click)="deleteItem(currentReceipt.id, item.id)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </mat-action-list>




                      </div>

                    </div>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>
          </section>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <img alt="Receipt image" src="{{currentReceipt.url}}">


</div>