<h1 mat-dialog-title>Update Friend's Venmo</h1>
<div mat-dialog-content>


    <!-- <p>Share this qrcode with your friends</p>

    <p>Restaurant: {{data.receipt.merchant_name}}</p>
    <p>Group total: ${{data.receipt.total | number: "1.2-2"}}</p>
    <p>Date: {{data.receipt.tran_date?.toDate() | date:'yyyy/MM/dd h:mm a'}}</p> -->
    <!-- <code>VenmoProfile: {{ venmoProfile | json}}</code> -->

    <div *ngIf="venmoProfile">
        Venmo Display Name: {{venmoProfile.display_name}} <img alt="profile image for {{venmoProfile.display_name}}"
            *ngIf="venmoProfile.profile_picture_url?.indexOf('no-image') === -1" class="left"
            src="{{venmoProfile.profile_picture_url}}" style="float:left;width:100px;height:100px;">
    </div>

    <p>Old Venmo: {{data.venmo}}</p>
    <p>New Venmo: {{venmo}}</p>

    <!-- <p>Data: {{data | json }}</p> -->
    <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)" [enable]="scannerEnabled"></zxing-scanner>


</div>
<div mat-dialog-actions>

    <button mat-button (click)="onNoClick()">Cancel</button>
    <!-- <button mat-button color="accent" (click)="scanAgain()">Scan Again</button> -->
    <button mat-button color="accent" (click)="updateFriendVentmo()">Update</button>

</div>