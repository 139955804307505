<h1 mat-dialog-title>Calculating tax percentage</h1>
<div mat-dialog-content>

    <mat-form-field>
        <mat-label>Tax Amount in $</mat-label>
        <input type='number' matInput value="{{data.tax | number: '1.2-2'}}" (change)="onChange($event)">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button><button mat-button color="accent"
        [mat-dialog-close]="data.tax_perecent" cdkFocusInitial>Update</button>
</div>