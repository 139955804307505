<div>
  <div>
    <h4>{{ title }} <button *ngIf="showLoginButton" mat-icon-button (click)="auth.login()">
        <mat-icon>account_circle</mat-icon>
      </button>

      <span *ngIf="notification != 'denied'">

        <!-- @ts-expect-error promptUser can not be found even though it exists -->
        <button *ngIf="notification=='granted' && auth.userInfo" mat-icon-button (click)="deleteToken()">
          <mat-icon>notifications</mat-icon>
        </button>

        <!-- @ts-expect-error promptUser can not be found even though it exists -->
        <!-- [ngStyle]="{'color':'yellow'}" -->
        <button *ngIf="notification =='default' && auth.userInfo" mat-icon-button (click)="requestPermission()">
          <mat-icon>notifications_off</mat-icon>
        </button>
      </span>


      <button *ngIf="showLogoutButton" mat-icon-button (click)="auth.logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </h4>
    <!--
    <button (click)="requestTokenOneTime()">
      requestTokenOneTime
    </button>
    <button (click)="getToken()">
      GetToken
    </button> -->
    <div *ngIf="auth.userInfo as userInfo">
      Welcome, {{ userInfo.displayName }}
    </div>


  </div>
  <!--
  <div *ngIf="auth.user | async as rawuser">
    auth.user
    <pre>
      {{ rawuser| json }}
    </pre>
  </div>

  <div *ngIf="auth.user$ | async as user">
    auth.user$
    <pre>
      {{ user| json }}
    </pre>
  </div> -->


  <router-outlet></router-outlet>
  <div>Version: {{ version }}</div>
  <button (click)="reload()">Reload</button>

</div>