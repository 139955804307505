<div fxLayout="column" fxLayoutAlign="space-around center">
  <mat-card style="width: 90%; margin-bottom: 20px;">

    <mat-card-title>Add new receipt</mat-card-title>


    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="space-around stretch">
        <section>
          <button mat-flat-button color="primary" (click)="uploadReceipt()">Select and upload receipt</button>
          <div *ngIf="uploadMessage">{{uploadMessage}}</div>
          <div *ngIf="currentReceiptUpload">
            <mat-progress-bar mode="determinate" [value]="percentage"></mat-progress-bar>
            <div attr.aria-valuenow="{{ percentage }}" aria-valuemin="0" aria-valuemax="100"
              [ngStyle]="{ width: percentage + '%' }">
              {{ percentage }}%
            </div>
          </div>
        </section>
      </div>
    </mat-card-content>
  </mat-card>
</div>