import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { Receipt } from 'src/app/models/receipt';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ReceiptDetailsComponent } from '../receipt-details/receipt-details.component';

@Component({
  selector: 'app-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.css']
})
export class ReceiptListComponent implements OnInit {
  displayedColumns: string[] = [
    'status',
    'tran_date',
    'merchant_name',
    'total',
    'actions'
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // dataSource: any[] = [];
  receipts?: Receipt[];
  currentReceipt?: Receipt;

  @Input()
  currentReceiptId: string;
  scrollToReceipt = false;
  deleteReceiptID: string;
  constructor(public uploadService: ReceiptService, public auth: AuthService) {}

  receiptDetail: ReceiptDetailsComponent;

  @ViewChild(ReceiptDetailsComponent, { static: false })
  set childComponent(child: ReceiptDetailsComponent) {
    if (child) {
      this.receiptDetail = child;
      if (this.scrollToReceipt) {
        this.scrollToReceiptNow();
      }
    }
  }

  scrollToReceiptNow() {
    if (this.receiptDetail) {
      this.receiptDetail.scrollToReceipt = true;
      this.receiptDetail.scrollToReceiptNow();
    }
  }

  ngOnInit(): void {
    this.retrieveReceipts();
  }
  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  restoreReceipt(status: string, id: string): void {
    this.deleteReceiptID = id;
    const new_status = 'private';
    this.uploadService.restoreReceipt(id, new_status, '');
  }
  deleteReceipt(status: string, id: string): void {
    if (confirm('Are you sure you want to delete?') == true) {
      this.deleteReceiptID = id;
      if (
        status == 'deleted' &&
        this.auth.userClaim &&
        this.auth.userClaim.admin
      ) {
        this.uploadService.realDeleteReceipt(id);
      } else {
        this.uploadService.deleteReceipt(id);
      }
    }
    // this.receiptDetail.scrollToReceipt = false;
    // this.receiptDetail.scrollComplete = true;
  }

  // ngAfterViewInit() {}

  retrieveReceipts(): void {
    // this.uploadService
    //   .getCombineReceipts()
    //   .pipe(
    //     map((changes) => {
    //     })
    //   )
    //   .subscribe((data) => {
    //     console.log(data);
    //   });

    // this.uploadService
    //   .getAll()
    //   .snapshotChanges()

    this.uploadService
      .getCombineReceipts()
      .pipe(
        map((changes) => {
          // console.log('ReceiptsSnapshotChanges');
          // console.table(changes);

          const data = changes.map((c: any) => ({
            ...c.payload.doc.data(),
            id: c.payload.doc.id
          }));

          console.log('data[0]');
          console.log(data[0]);
          return data.sort(
            (
              a: { createdAt: { seconds: number } },
              b: { createdAt: { seconds: number } }
            ) =>
              (b.createdAt?.seconds || 99999999999) -
              (a.createdAt?.seconds || 99999999999)
          );
        })
      )
      .subscribe((data: Receipt[]) => {
        this.receipts = data;
        const lastFilter = this.dataSource?.filter;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        if (lastFilter) {
          this.dataSource.filter = lastFilter;
        }
        let receipt;
        if (this.currentReceiptId) {
          receipt = data.find(
            ({ id }) => id === this.currentReceiptId
          ) as Receipt;

          if (receipt) {
            // when you upload a receipt, you get an currentReceiptId
            // But the list is not yet update to have it

            this.setActiveReceipt(receipt);
          }

          // this.currentReceipt = receipt;
        } else {
          this.setActiveReceipt(data[0]);
        }
      });
  }
  setActiveReceipt(receipt: Receipt): void {
    console.log('setActiveReceipt');
    this.currentReceipt = receipt;
    this.currentReceiptId = receipt.id;
  }

  clickRow(receipt: Receipt): void {
    this.setActiveReceipt(receipt);
    if (receipt.id != this.deleteReceiptID) {
      this.receiptDetail.scrollComplete = false;
      this.scrollToReceipt = true;
      this.scrollToReceiptNow();
    }
  }

  trackByUid(index: number, item: Receipt) {
    return item.id;
  }
}
