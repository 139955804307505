import {
  Auth,
  authState,
  signInAnonymously,
  signOut,
  User,
  GoogleAuthProvider,
  signInWithPopup
} from '@angular/fire/auth';
import { EMPTY, Observable, of } from 'rxjs';

import { switchMap, tap, take, map } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { UserInfo } from './user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<any>;
  userClaims$: Observable<any>;

  friends$: Observable<any>;

  public friends: { [key: string]: any } = {};

  public userInfo: UserInfo | null = null;
  public userClaim: any;

  public readonly user: Observable<User | null> = EMPTY;

  async login() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });

    const credential = await signInWithPopup(this.auth, provider);

    this.updateUserData(credential.user);
    // TODO sign into offline app
  }

  async loginAnonymously() {
    const credential = await signInAnonymously(this.auth);
    // TODO sign into offline app
  }

  async logout() {
    // this.auth.signOut();
    return await signOut(this.auth);
    // TODO sign out of offline app
  }

  public updateUserVenmo(venmo: string) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${this.userInfo?.uid}`
    );
    const data = {
      venmo: venmo
    };

    return userRef.set(data, { merge: true });
  }
  private updateUserData(user: any) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    };

    return userRef.set(data, { merge: true });
  }

  public updateFriendVenmo(friend: string, venmo: string) {
    // return userRef.set(data, { merge: true });
    // this.userInfo?.uid;
    const friendRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${this.userInfo?.uid}/friends/${friend.toLowerCase()}`
    );
    const data = {
      venmo: venmo
    };
    console.log('updateFriendVenmo', friend, venmo);
    return friendRef.set(data, { merge: true });
  }

  constructor(public auth: Auth, private afs: AngularFirestore) {
    this.user = authState(this.auth);
    this.user$ = this.user.pipe(
      tap((user) => {
        console.log('user.pipe', user);
      }),
      switchMap((user) => {
        console.log('switchMap', user);
        if (user) {
          // console.log('switchMap', user);
          this.afs
            .doc(`users/${user.uid}`)
            .collection('friends')
            .snapshotChanges()
            .pipe(
              // take(1),
              map((changes) => {
                return Object.assign(
                  {},
                  ...changes.map((c) => ({
                    [c.payload.doc.id.toLowerCase()]: c.payload.doc.data()
                  }))
                );
              })
            )
            .subscribe((data) => {
              this.friends = data;
            });
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          this.friends = {};
          return of(null);
        }
      })
    );

    this.user$.subscribe((data) => {
      console.log('userInfo', data);
      this.userInfo = data;
    });

    this.userClaims$ = this.user.pipe(
      tap((user) => {
        console.log('user.pipe', user);
      }),
      switchMap((user) => {
        console.log('switchMap', user);
        if (user) {
          // const force = true;
          const force = false;
          return user.getIdTokenResult(force).then((idTokenResult) => {
            // `idTokenResult.claims` will contain the custom claims associated with the user

            return idTokenResult.claims;
          });
          // console.log('switchMap', user);
        } else {
          return of(null);
        }
      })
    );
    this.userClaims$.subscribe((data) => {
      console.log('userClaims', data);

      this.userClaim = data;
    });
  }
}
