<h1 mat-dialog-title>Sharing</h1>
<div mat-dialog-content>
    <!-- <p>Share this qrcode with {{data.payer}} </p> -->
    <p>Restaurant: {{data.receipt.merchant_name}}</p>
    <!-- <p>Group total: ${{data.receipt.total}}</p> -->
    <p>Date: {{data.receipt.tran_date?.toDate() | date:'yyyy/MM/dd h:mm a'}}</p>
    <p>This link is generated for <b>{{data.payer}} ({{device}})</b> to pay <b>{{data.account}}</b> for a total of
        ${{data.amount |
        number: "1.2-2"}}
    </p>

    <!-- {{data.receipt.tran_date.toDate() | date:'yyyy/MM/dd h:mm a'}} -->
    <!-- <pre>${{data.receipt | json}}</pre> -->

    <qrcode [elementType]="'img'" [qrdata]="url" [allowEmptyString]="true" [width]="256" [errorCorrectionLevel]="'M'">
    </qrcode>

    <!-- {{data.url}} -->
</div>
<div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent"
        [mat-dialog-close]="data.tax_perecent" cdkFocusInitial>Update</button> -->

    <button mat-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent" (click)="updateDevice('iOS')">iOS(iphone, ipad)</button>
    <button mat-button color="accent" (click)="updateDevice('android')">Android</button>

    <!-- <button mat-button color="accent" cdkFocusInitial (click)="copyLink()">Copy link
        to clipboard</button> -->


</div>