import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ReceiptService } from 'src/app/services/receipt.service';
import { map } from 'rxjs/operators';
import { MatAccordion } from '@angular/material/expansion';
import { ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { Timestamp } from '@firebase/firestore';

import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, tap } from 'rxjs/operators';
// import { deleteField } from '@angular/fire/firestore';
import { Receipt } from 'src/app/models/receipt';
import { ReceiptItem } from 'src/app/models/receipt-item';
import { AuthService } from 'src/app/services/auth.service';
import { VenmoPipe } from 'src/app/venmo.pipe';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ReceiptDetailsComponent implements OnInit, OnChanges, OnDestroy {
  showTranslation = false;
  scrollToPeople = false;
  scrollToReceipt = false;
  topPeopleElement: ElementRef;
  topReceiptElement: ElementRef;
  scrollComplete = false;
  highLightMissingItems = false;
  @ViewChild('topPeople', { static: false })
  set topPeople(element: ElementRef) {
    if (element) {
      this.topPeopleElement = element;
    }
    if (this.topPeopleElement && this.scrollToPeople) {
      this.scrollToElement(this.topPeopleElement);
    }
  }
  @ViewChild('topReceipt', { static: false })
  set topReceipt(element: ElementRef) {
    if (element) {
      this.topReceiptElement = element;
    }
    if (this.scrollToReceipt) {
      this.scrollToElement(this.topReceiptElement);
    }
  }
  scrollToElement(element: ElementRef) {
    if (!this.scrollComplete) {
      const nElement = element.nativeElement;
      nElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.scrollComplete = true;
    }
  }
  scrollToReceiptNow() {
    // if (this.scrollToReceipt && this.topReceiptElement) {
    if (this.scrollToReceipt && this.topReceiptElement) {
      this.scrollToElement(this.topReceiptElement);
    }
  }

  @ViewChild(MatAccordion)
  accordion!: MatAccordion;

  @ViewChild(MatAutocompleteTrigger)
  autocomplete!: MatAutocompleteTrigger;

  @Input() currentReceipt: Receipt;
  subscription: any;
  quickEdit = true;
  openItems: string[];
  venmoProfile: any;
  items?: ReceiptItem[];
  // currentReceipt: Receipt;

  // any = {
  //   merchant_name: '',
  //   subtotal: 0,
  //   total: 0,
  //   confirmed_subtotal: false,
  //   tran_date: null
  // };
  message = '';
  subtotal_match = false;
  subtotal_diff = 0;
  total_match = false;
  total_diff = 0;
  calculated_subtotal = 0;
  itemized_subtotal = 0;
  total_tip = 0;
  total_tax = 0;
  calculated_total = 0;
  breakdown: any[] = [];
  cost_breakdown_detail: { [key: string]: any } = {};
  displayedColumns: string[] = ['name', 'total', 'subtotal', 'tip', 'tax'];
  expandedElement: any;

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  fruitCtrl = new FormControl('');
  myControl = new FormControl('');
  filteredFruits: Observable<string[]>;
  allFruits: string[] = [
    'Chao',
    'Claudia',
    'Ceci',
    'Nathalie',
    'Leah',
    'MikeC',
    'MikeH',
    'Amy',
    'Josh',
    'Jim',
    'Matt'
  ].sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  @ViewChild('fruitInput')
  fruitInput!: ElementRef<HTMLInputElement>;

  constructor(
    private uploadService: ReceiptService,
    public auth: AuthService,
    public dialog: MatDialog,
    http: HttpClient,
    public functions: Functions,
    private clipboard: Clipboard,
    private venmoPipe: VenmoPipe,
    private sanitizer: DomSanitizer
  ) {
    // console.log('ReceiptDetailsComponent constructor');
    this.openItems = [];
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      // tap((el) => console.log('Process ' + el)),
      // map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice().filter(x => ! this.currentReceipt.people.includes(x)))),

      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  sanitizeUrl(
    account: string,
    type: string,
    amount: number,
    merchant_name: string,
    note: string,
    audience: string
  ): SafeUrl {
    const url = this.venmoPipe.transform(
      account,
      type,
      amount,
      merchant_name,
      note,
      audience
      // 'private'
    );
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getLink(): string {
    // FIXME this is getting run multiple times

    return `${window.location.origin}/receipts/${this.currentReceipt.id}`;
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      const people = value.split(',').map((name) => name.trim());
      people.forEach((person) => {
        if (person && !this.currentReceipt.people.includes(person)) {
          this.currentReceipt.people.push(person);
        }
      });

      event.input.focus();
    }
    // Clear the input value
    event.chipInput?.clear();
    this.fruitCtrl.setValue(null);

    // this.autocomplete.closePanel()
    this.update();
  }

  removeItemFromOpenPanel(itemId: string) {
    const index = this.openItems.indexOf(itemId);

    if (index >= 0) {
      this.openItems.splice(index, 1);
    }
  }
  remove2(fruit: string): void {
    const index = this.currentReceipt.people.indexOf(fruit);

    if (index >= 0) {
      this.currentReceipt.people.splice(index, 1);
    }
    // console.log(this.fruits)
    this.update();
  }

  isFruitSelected(fruit: string): boolean {
    return this.currentReceipt.people.indexOf(fruit) >= 0;
  }

  onFruitSelectionChange(event: any): void {
    const option = event.options[0];
    const value = option.value;
    if (option._selected) {
      this.currentReceipt.people.push(value);
    } else {
      const index = this.currentReceipt.people.indexOf(value);

      if (index >= 0) {
        this.currentReceipt.people.splice(index, 1);
      }
    }

    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.update();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // event.stopPropagation();
    this.currentReceipt.people.push(event.option.viewValue);
    // FIXME
    // My guess is that so we dont end up submit value of this
    this.fruitInput.nativeElement.value = '';
    //  Set null so it will retrigger the filter for allFrfulters
    this.fruitCtrl.setValue(null);
    this.update();
  }

  private _filter(value: string): string[] {
    return this.allFruits.slice();
    const filterValue = value.toLowerCase();
    return this.allFruits.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy(): void {
    // console.log('ReceiptDetailsComponent ngOnDestory');
  }
  ngOnInit(): void {
    // console.log('ReceiptDetailsComponent ngOnInit');

    this.message = '';
    // if (this.currentReceipt.total == undefined){
    //   this.update()
    // }

    // let startTime = new Date().getTime();
  }
  ngOnChanges(): void {
    // console.log('ReceiptDetailsComponent ngOnChanges');
    this.message = '';
    // const people_str = (this.receipt.people || []).join(" ")

    if (!this.currentReceipt.people) {
      this.currentReceipt.people = [];
    }
    // this.currentReceipt = { ...this.receipt, people_str: people_str };
    // FIXME
    // this.currentReceipt = { ...this.receipt };
    this.currentReceipt.total =
      this.currentReceipt.subtotal *
      (1 + this.currentReceipt.tip / 100.0 + this.currentReceipt.tax / 100.0);

    if (
      this.currentReceipt.venmo == undefined ||
      this.currentReceipt.venmo == ''
    ) {
      this.venmoProfile = undefined;
    } else if (this.currentReceipt.venmo != this.venmoProfile?.username) {
      this.updateVenmo(this.currentReceipt.venmo);
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.uploadService
      .getReceiptItems(this.currentReceipt.id)
      .snapshotChanges()
      .pipe(
        // debounceTime(250),
        map((changes) => {
          console.log('receiptItems snapshotChanges', changes.length);
          // console.table(changes);
          // startTime = new Date().getTime()
          return changes.map((c) => ({
            ...c.payload.doc.data(),
            id: c.payload.doc.id
          }));
        })
      )
      .subscribe((data) => {
        this.items = data;
        this.cost_breakdown_detail = {};
        this.calculated_subtotal = 0;
        this.total_tip = 0;
        this.total_tax = 0;
        this.calculated_total = 0;
        this.itemized_subtotal = 0;
        this.highLightMissingItems = false;
        let numNotAssignedItem = 0;

        data.forEach((item) => {
          const total_num = (item.people || []).length;
          const unit_price = item.total_price / total_num;
          this.calculated_subtotal += item.total_price;

          if (item.people == null || item.people.length == 0) {
            numNotAssignedItem += 1;
          }
          if (item.total_price && total_num) {
            item.people.forEach((p: string) => {
              if (p in this.cost_breakdown_detail) {
                this.cost_breakdown_detail[p]['subtotal'] += unit_price;
              } else {
                this.cost_breakdown_detail[p] = {};
                this.cost_breakdown_detail[p]['name'] = p;
                this.cost_breakdown_detail[p]['subtotal'] = unit_price;
              }

              if (!('items' in this.cost_breakdown_detail[p])) {
                // console.log(p, "item")
                this.cost_breakdown_detail[p]['items'] = [];
              }
              // console.log(p, item.name)
              let name = `${item.name}`;
              if (total_num != item.quantity) {
                name = `${item.quantity} ${item.name} ($${
                  item.total_price
                }) shared by ${total_num} people, per person $${unit_price.toFixed(
                  2
                )}`;
              } else {
                name = `${item.name} $${unit_price.toFixed(2)}`;
              }

              this.cost_breakdown_detail[p]['items'].push({
                name: name,
                unit_price: unit_price,
                num_shared: total_num
              });
            });
          }
        });
        if (numNotAssignedItem <= 2) {
          this.highLightMissingItems = true;
        }
        Object.keys(this.cost_breakdown_detail).forEach((key) => {
          const value = this.cost_breakdown_detail[key];
          value['tip'] = (value['subtotal'] * this.currentReceipt.tip) / 100.0;
          value['tax'] = (value['subtotal'] * this.currentReceipt.tax) / 100.0;
          value['total'] = value['subtotal'] + value['tip'] + value['tax'];

          value['note'] = `Food: $${value['subtotal'].toFixed(2)}`;

          value['note'] += `\nTax: $${value['tax'].toFixed(
            2
          )} (${this.currentReceipt.tax.toFixed(2)} percent ) \nTip: $${value[
            'tip'
          ].toFixed(2)} (${this.currentReceipt.tip.toFixed(2)} percent )\n`;

          value['note'] += value['items']
            .map((user: { name: string }) => user.name)
            .join('\n');

          // // %25 is % in url
          // value['note'] += `%0ATax%20$${value['tax'].toFixed(
          //   2
          // )}%20(${this.currentReceipt.tax.toFixed(1)}%25)%20%0ATip%20$${value[
          //   'tip'
          // ].toFixed(2)}%20(${this.currentReceipt.tip.toFixed(1)}%25)`;

          this.total_tax += value['tax'];
          this.total_tip += value['tip'];
          this.itemized_subtotal += value['subtotal'];
          this.calculated_total += value['total'];
        });

        this.currentReceipt.people.forEach((person) => {
          if (!(person in this.cost_breakdown_detail)) {
            this.cost_breakdown_detail[person] = {
              items: [],
              name: person,
              note: '',
              subtotal: 0,
              tip: 0,
              tax: 0,
              total: 0
            };
          }
        });
        this.breakdown = Object.values(this.cost_breakdown_detail).sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        this.subtotal_diff = Math.abs(
          this.calculated_subtotal - this.currentReceipt.subtotal
        );

        this.subtotal_match = Math.abs(this.subtotal_diff) < 0.01;

        this.total_diff = this.calculated_total - this.currentReceipt.total;
        this.total_match = Math.abs(this.total_diff) < 0.01;
        // const endTime = new Date().getTime();
        // console.log(`process snapshotChanges taken ${(endTime - startTime) } mseconds`);
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogTip, {
      hasBackdrop: true,
      width: '300px',
      data: {
        subtotal: this.currentReceipt.subtotal,
        tip: (this.currentReceipt.subtotal * this.currentReceipt.tip) / 100,
        tip_perecent: this.currentReceipt.tip
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentReceipt.tip = result;
        this.update();
      }
    });
  }

  openTaxDialog(): void {
    const dialogRef = this.dialog.open(DialogTax, {
      hasBackdrop: true,
      width: '300px',
      data: {
        subtotal: this.currentReceipt.subtotal,
        tax: (this.currentReceipt.subtotal * this.currentReceipt.tax) / 100,
        tax_perecent: this.currentReceipt.tax
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentReceipt.tax = result;
        this.update();
      }
    });
  }

  openSharingDialog(): void {
    const dialogRef = this.dialog.open(DialogSharing, {
      hasBackdrop: true,
      width: '300px',
      data: {
        url: `${window.location.origin}/receipts/${this.currentReceipt.id}`,
        receipt: this.currentReceipt
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
  updateMyVenmo() {
    this.auth.updateUserVenmo(this.currentReceipt.venmo as string);
  }
  openFriendVenmoDialog(friend: string, venmo: any): void {
    const dialogRef = this.dialog.open(DialogFriendVenmo, {
      hasBackdrop: true,
      width: '300px',
      data: {
        friend: friend,
        venmo: venmo?.venmo
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openVenmoPaymentDialog(
    payer: string,
    payee: string,
    amount: number,
    merchant_name: string,
    note: string,
    audience: string
  ): void {
    // <a mat-raised-button color="accent" (click)=" openVenmoPaymentDialog(element.name, currentReceipt.venmo, element.total,
    //   currentReceipt.merchant_name,element.note )">Pay
    //   via QRCode</a>
    // <!-- href="{{ currentReceipt.venmo | venmo:'pay':element.total:currentReceipt.merchant_name:element.note:'private'}}" -->

    const dialogRef = this.dialog.open(DialogVenmoPayment, {
      hasBackdrop: true,
      width: '600px',
      data: {
        payer: payer,
        account: payee,
        type: 'pay',
        amount: amount,
        merchant_name: merchant_name,
        note: note,
        audience: audience,
        receipt: this.currentReceipt
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  public output = 'scanning';

  public scannerEnabled = false;

  currentVenmo: string | null = null;
  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
  public updateVenmo(id: string) {
    if (id == '') {
      this.currentReceipt.venmo = '';
      return this.update();
    }
    const callable = httpsCallableData(this.functions, 'venmo');
    const data = callable({ userId: id });
    data.subscribe((r: any) => {
      this.scannerEnabled = false;
      if (Object.keys(r).length === 0) {
        this.venmoProfile = {
          display_name: 'invalid user, please double check'
        };
        return;
      } else {
        if (this.currentReceipt.venmo != r['username']) {
          this.currentReceipt.venmo = r['username'];
          this.update();
        }
        this.venmoProfile = r;
      }
    });
  }
  public scanSuccessHandler(event: any) {
    const regexpSize = /(.*)?user_id=(.*)&created(.*)/;
    const match = event.match(regexpSize);

    if (match && match[2] != this.currentVenmo) {
      this.currentVenmo = match[2];
      console.log(match[2]);
      this.updateVenmo(match[2]);
    }
  }
  update(): void {
    this.currentReceipt.total =
      this.currentReceipt.subtotal *
      (1 + this.currentReceipt.tip / 100.0 + this.currentReceipt.tax / 100.0);
    // FIXME, why do i need to create new variable instead of jus using the receipt object
    this.uploadService.updateReceipt(this.currentReceipt.id, {
      ...this.currentReceipt
    });
  }

  updateReceiptItem(
    id: string,
    itemId: string,
    data: Partial<ReceiptItem>
  ): void {
    this.uploadService.updateReceiptItem(id, itemId, data);
  }

  deleteItem(id: string, itemId: string): void {
    this.uploadService.deleteReceiptItem(id, itemId);
  }

  addEveryoneToReceiptItem(receipt: Receipt, item: ReceiptItem) {
    if (this.currentReceipt.people) {
      this.uploadService.addEveryoneToReceiptItem(
        this.currentReceipt.id,
        item.id,
        this.currentReceipt.people
      );
    }
  }

  toggleLike(receiptId: string, item: any) {
    item.like = !item.like;
    this.uploadService.updateReceiptItem(this.currentReceipt.id, item.id, {
      like: item.like
    });
  }
  togglePeople(receiptId: string, item: ReceiptItem, person: string) {
    if (item.people && item.people.includes(person)) {
      this.uploadService.removePeopleFromReceiptItem(
        this.currentReceipt.id,
        item.id,
        person
      );
    } else {
      this.uploadService.addPeopleToReceiptItem(
        this.currentReceipt.id,
        item.id,
        person
      );
    }
  }

  splitReceiptItem(receiptId: string, item: ReceiptItem) {
    this.uploadService.splitReceiptItem(receiptId, item);
  }

  addNewItem(receiptID: string) {
    let createdAt;
    if (this.items) {
      const item = this.items[0];

      createdAt = new Date(item.createdAt.toDate().getTime() - 1000);

      // new Date(date.getTime() - 1000);

      // createdAt = new Timestamp(
      //   item.createdAt.seconds - 1,
      //   item.createdAt.nanoseconds
      // );
    } else {
      createdAt = null;
    }

    const receiptItemId = this.uploadService.addReceiptItem(
      receiptID,
      createdAt
    );
    this.openItems.push(receiptItemId);

    // this.uploadService.addReceiptItem(receiptID, createdAt);
    this.quickEdit = false;
  }
  trackByName(index: number, item: ReceiptItem) {
    return item.name;
  }

  hasVenmo(person: string) {
    person = person.toLocaleLowerCase();
    return person in this.auth.friends;
  }

  shouldShowCharge(venmo: any, person: any): boolean {
    person = person.toLocaleLowerCase();
    return (
      person in this.auth.friends && this.auth.friends[person]['venmo'] != venmo
    );
  }

  paidForMeal(venmo: string, person: string): boolean {
    person = person.toLocaleLowerCase();
    if (person == 'chao') {
      console.log('paidForMeal', venmo, person);
    }
    return (
      person in this.auth.friends && this.auth.friends[person]['venmo'] == venmo
    );
  }
}

export interface DialogData {
  subtotal: number;
  tip_perecent: number;
  tip: number;
}

@Component({
  selector: 'dialog-tip',
  templateUrl: './dialog-tip.html'
})
export class DialogTip {
  constructor(
    public dialogRef: MatDialogRef<DialogTip>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange2(event: any): void {
    console.log('onChange2');
    this.data.tip = parseFloat(event.target.value);
    this.data.tip_perecent = (this.data.tip * 100) / this.data.subtotal;
  }
}

export interface DialogTaxData {
  subtotal: number;
  tax_perecent: number;
  tax: number;
}

@Component({
  selector: 'dialog-tax',
  templateUrl: './dialog-tax.html'
})
export class DialogTax {
  constructor(
    public dialogRef: MatDialogRef<DialogTip>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTaxData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(event: any): void {
    console.log('onChange');
    this.data.tax = parseFloat(event.target.value);
    this.data.tax_perecent = (this.data.tax * 100) / this.data.subtotal;
  }
}

export interface DialogSharingData {
  url: string;
  receipt: Receipt;
}

@Component({
  selector: 'dialog-sharing',
  templateUrl: './dialog-sharing.html'
})
export class DialogSharing {
  constructor(
    public dialogRef: MatDialogRef<DialogSharing>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSharingData,
    private clipboard: Clipboard
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  copyLink() {
    this.clipboard.copy(this.data.url);
  }
}

export interface DialogVenmoPaymentData {
  account: string;
  type: string;
  amount: number;
  merchant_name: string;
  note: string;
  audience: string;
  receipt: Receipt;
  payer: string;
}

@Component({
  selector: 'dialog-venmo-payment',
  templateUrl: './dialog-venmo-payment.html'
})
export class DialogVenmoPayment {
  url: string;
  device: string;
  constructor(
    public dialogRef: MatDialogRef<DialogVenmoPayment>,
    @Inject(MAT_DIALOG_DATA) public data: DialogVenmoPaymentData,
    private clipboard: Clipboard,
    private venmoPipe: VenmoPipe,
    private deviceService: DeviceDetectorService
  ) {
    this.device = this.deviceService.os;
    this.updateUrl();
  }

  // this.data.url = this.venmoPipe.transform(
  //   this.data.payee,
  //   'pay',
  //   this.data.amount,
  //   this.data.receipt.merchant_name,
  //   this.data.receipt.note,
  //   'private'
  // );
  updateUrl(): void {
    this.url = this.venmoPipe.transform(
      this.data.account,
      this.data.type,
      this.data.amount,
      this.data.merchant_name,
      this.data.note,
      this.data.audience,
      this.device
      // 'private'
    );
  }

  updateDevice(device: string) {
    this.device = device;
    this.updateUrl();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogFriendVenmoData {
  friend: string;
  venmo: string;
}

@Component({
  selector: 'dialog-friend-venmo',
  templateUrl: './dialog-friend-venmo.html'
})
export class DialogFriendVenmo {
  lastScanValue: string;
  venmo: string;
  venmoProfile: any;
  cache: { [key: string]: string } = {};
  scannerEnabled = true;
  successEvents = new Set<string>();
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  constructor(
    public dialogRef: MatDialogRef<DialogFriendVenmo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFriendVenmoData,
    private authService: AuthService,
    public functions: Functions
  ) {
    // this.venmo = this.data.venmo;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  // scanAgain(): void {
  //   this.scanner.scanStart();
  // }
  async scanSuccessHandler(event: any): Promise<void> {
    if (this.lastScanValue == event) {
      return;
    } else {
      this.lastScanValue = event;
    }
    // if (this.successEvents.has(event)) {
    //   return;
    // } else {
    //   this.successEvents.add(event);
    // }
    // console.log('scanSuccessHandler');
    // console.log(event);
    // // this.venmo = $event;
    // // this.scannerEnabled = false;
    // // 'https://venmo.com/code?user_id=1673783458922496802&created=1693186078'

    // this.scanner.scanStop();

    const regexpSize = /(.*)?user_id=(.*)&created(.*)/;
    const match = event.match(regexpSize);
    // match[2] = '1242182447005696519';
    const id = match[2];
    if (this.cache.hasOwnProperty(id)) {
      this.venmoProfile = this.cache[id];
      this.venmo = this.venmoProfile['username'];
      return;
    }

    const callable = httpsCallableData(this.functions, 'venmo');
    // const data = callable({ userId: id });
    const data = (await callable({ userId: id }).toPromise()) as any;

    if (data['username']) {
      this.cache[id] = data;
      this.venmoProfile = data;
      this.venmo = this.venmoProfile['username'];
    }
  }
  updateFriendVentmo() {
    this.authService.updateFriendVenmo(
      this.data.friend,
      this.venmoProfile['username']
    );
    this.dialogRef.close();
  }
}
