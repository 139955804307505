<h1 mat-dialog-title>Calculating tip percentage</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Tip Amount in $</mat-label>
        <input type='number' matInput value="{{data.tip | number: '1.2-2'}}" (change)="onChange2($event)">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button><button mat-button color="accent"
        [mat-dialog-close]="data.tip_perecent" cdkFocusInitial>Update</button>
</div>