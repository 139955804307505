export const environment = {
  firebase: {
    projectId: 'amc-munchies',
    appId: '1:999499673258:web:2b3dfe3149867b3764aad3',
    storageBucket: 'amc-munchies.appspot.com',
    locationId: 'us-east1',
    apiKey: 'AIzaSyCzh00TU7cPeBVk8WdGbPRGZCgDz0p-1M8',
    authDomain: 'munchies.heychao.com',
    messagingSenderId: '999499673258'
  },
  production: true
};
