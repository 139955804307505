<h1 mat-dialog-title>Sharing</h1>
<div mat-dialog-content>

    <!-- <mat-form-field>
        <mat-label>Tax Amount in $</mat-label>
        <input type='number' matInput value="{{data.tax | number: '1.2-2'}}" (change)="onChange($event)">
    </mat-form-field> -->

    <p>Share this qrcode with your friends</p>

    <p>Restaurant: {{data.receipt.merchant_name}}</p>
    <p>Group total: ${{data.receipt.total | number: "1.2-2"}}</p>
    <p>Date: {{data.receipt.tran_date?.toDate() | date:'yyyy/MM/dd h:mm a'}}</p>
    <!-- {{data.receipt.tran_date.toDate() | date:'yyyy/MM/dd h:mm a'}} -->
    <!-- <pre>${{data.receipt | json}}</pre> -->

    <qrcode [elementType]="'img'" [qrdata]="data.url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>


    <!-- {{data.url}} -->
</div>
<div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent"
        [mat-dialog-close]="data.tax_perecent" cdkFocusInitial>Update</button> -->

    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="accent" cdkFocusInitial (click)="copyLink()">Copy link
        to clipboard</button>


</div>