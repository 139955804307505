import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({
  name: 'venmo'
})
export class VenmoPipe implements PipeTransform {
  constructor(private deviceService: DeviceDetectorService) {}
  transform(
    account: string,
    type: string,
    amount: number,
    merchant_name: string,
    note: string,
    audience: string,
    device?: string
  ): string {
    let url;
    if (!device) {
      device = this.deviceService.os;
    }

    if (device === 'iOS') {
      url = `venmo://paycharge?recipients=${account}&txn=${type}&audience=${audience}&amount=${amount.toFixed(
        2
      )}&note=${encodeURIComponent(merchant_name + '\n\n' + note)}`;
    } else {
      url = `https://venmo.com/${account}?txn=${type}&audience=${audience}&amount=${amount.toFixed(
        2
      )}&note=${encodeURIComponent(merchant_name + '\n\n' + note)}`;
    }
    return url;
  }
}
