export class ReceiptUpload {
  // key!: string;
  // name!: string;
  id: string;
  url?: string;
  data: string;
  originalSize?: number;
  newSize?: number;
  // id:string, url:string,
  constructor(id: string, data: string) {
    this.id = id;
    // this.url = url
    this.data = data;
  }
}
