import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { ReceiptUpload } from 'src/app/models/receipt-upload.model';
import {
  DataUrl,
  NgxImageCompressService,
  UploadResponse
} from 'ngx-image-compress';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.css']
})
export class UploadFormComponent implements OnInit {
  @Output()
  newReceiptEvent = new EventEmitter<string>();

  currentReceiptUpload?: ReceiptUpload;
  percentage = 0;
  uploadMessage = '';
  constructor(
    private uploadService: ReceiptService,
    private imageCompress: NgxImageCompressService
  ) {}
  ngOnInit(): void {
    console.log('UploadFormComponent ngOnInit');
  }

  uploadReceipt() {
    const startTime = new Date().getTime();
    this.currentReceiptUpload = undefined;
    this.uploadMessage = '';

    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation }: UploadResponse) => {
        this.uploadMessage =
          'Preprocessing receipt, getting it ready to upload';
        console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
        const endTime = new Date().getTime();
        console.log(
          `initial time taken ${(endTime - startTime) / 1000} seconds`
        );
        this.imageCompress
          .compressFile(image, orientation, 90, 90, 2048, 2048)
          .then((newImage: DataUrl) => {
            let data;
            const endTime2 = new Date().getTime();
            console.log(`time taken ${(endTime2 - endTime) / 1000} seconds`);
            console.warn(
              'Size in bytes is now:',
              this.imageCompress.byteCount(newImage)
            );
            if (
              this.imageCompress.byteCount(image) <
              this.imageCompress.byteCount(newImage)
            ) {
              console.log('new image is bigger');
              data = image;
            } else {
              data = newImage;
            }
            const id = this.uploadService.getNewId();
            this.currentReceiptUpload = new ReceiptUpload(id, data);
            this.currentReceiptUpload.originalSize =
              this.imageCompress.byteCount(image);
            this.currentReceiptUpload.newSize =
              this.imageCompress.byteCount(data);
            this.uploadService
              .pushDataURLToStorage(this.currentReceiptUpload)
              .subscribe({
                next: (percentage) => {
                  this.percentage = Math.round(percentage ? percentage : 0);
                },
                error: (e) => console.error(e),
                complete: () => {
                  this.newReceiptEvent.emit(id);
                }
              });
          });
      });
  }
}
