<div fxLayout="column" fxLayoutAlign="space-around center">
  <mat-card style="width: 90%; margin-bottom: 20px;">

    <mat-card-title>Choose your receipt</mat-card-title>


    <mat-card-content>
      <div>
        <div fxLayout="column" fxLayoutAlign="space-around stretch">
          <mat-form-field>
            <input matInput (keyup)="applyFilter(getValue($event))" placeholder="Filter">
          </mat-form-field>
          <!-- fixedLayout -->
          <!-- Not good because every columns is very different -->
          <table class="table-container" mat-table [dataSource]="dataSource" matSort [trackBy]="trackByUid" recycleRows>
            <ng-container matColumnDef="merchant_name">

              <th mat-header-cell *matHeaderCellDef mat-sort-header class="centre"> Restaurant </th>
              <td mat-cell *matCellDef="let element" class="centre">

                <ng-template #processing>
                  <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
                  Analyzing receipt, should take less than 10 seconds
                </ng-template>

                <div class="centr merchant-name" *ngIf="element.merchant_name.length!=0; else processing">
                  {{element.merchant_name}}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="tran_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="centre"> Transaction Date </th>
              <td mat-cell *matCellDef="let element" class="centre">
                <div class="centr trans-date">{{element.tran_date?.toDate() | date:'yyyy'}}
                  {{element.tran_date?.toDate()
                  | date:'MM/dd'}}
                  <!-- {{element.createdAt?.toDate() |date:'MM/dd/yyyy'}} -->
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> createdAt </th>
              <td mat-cell *matCellDef="let element"> {{element.createdAt?.toDate() | date:'yyyy/MM/dd h:mm:ss a'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
              <td mat-cell *matCellDef="let element"> ${{element.total | number:"1.2-2"}}
                <div *ngIf="element.people.length!=0">
                  (<b>{{element.people.length}}</b> <mat-icon style="vertical-align: middle;">people</mat-icon>)
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="subtotal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal </th>
              <td mat-cell *matCellDef="let element"> ${{element.subtotal | number:"1.2-2"}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <!-- fxLayoutGap="100px" -->
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let element">
                <!-- fxLayout="row" -->
                <span>

                  <ng-container *ngIf="auth.userInfo">
                    <ng-container *ngIf="element.status == 'public'; else unlock">
                      <button matTooltip="Lock receipt" mat-mini-fab color="warn"
                        (click)="this.uploadService.lock(element.id)">
                        <mat-icon>lock</mat-icon>
                      </button>
                    </ng-container>

                    <ng-template #unlock>
                      <button matTooltip="Unlock receipt" mat-mini-fab color="warn"
                        (click)="this.uploadService.unlock(element.id)">
                        <mat-icon>lock_open</mat-icon>
                      </button>
                    </ng-template>
                  </ng-container>

                  <button matTooltip="Delete receipt" mat-mini-fab color="warn"
                    (click)="deleteReceipt(element.status, element.id)">
                    <mat-icon>delete</mat-icon>
                  </button>

                  <ng-container *ngIf="element.status == 'deleted' && auth.userClaim?.admin">
                    <button matTooltip="Restore receipt" mat-mini-fab color="warn"
                      (click)="restoreReceipt(element.status, element.id)">
                      <mat-icon>restore</mat-icon>
                    </button>
                  </ng-container>

                </span>

              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="clickRow(row)"
              [class.row-is-clicked]="row.id == currentReceiptId">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[ 10, 25, 100]"></mat-paginator>

        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="currentReceipt">
  <app-receipt-details [currentReceipt]="currentReceipt"></app-receipt-details>
</div>